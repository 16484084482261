import React from 'react';
import {StaticImage} from 'gatsby-plugin-image';
import {Container, Row, Col, Card, Accordion} from 'react-bootstrap';
import 'animate.css';

import '../styles/faqblock.css'

const FAQBlock = () => {
    return(
        <div className=" mt-5">
            <Container className="service-section">
                <Row className="justify-content-center mb-5">
                    <Col className="service-heading text-center col-8">
                        <div>
                            <span className="gradient-text blue">
                                FAQs
                            </span>
                        </div>
                        <h2 className="svc-h2 mt-3 mb-3">
                            Frequently Asked Questions
                        </h2>
                        <p className="svc-text">
                            You have questions, we have answers. Check out some of our most commonly asked questions. Can’t find the answer you’re looking for? Contact us directly and we’ll be happy to help. 
                        </p>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col xs={12} md={6}>
                        <StaticImage
                            src="../images/digital-3.webp"
                            placeholder="blurred"
                            layout="constrained"
                            alt=""
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <Accordion defaultActiveKey="0">
                            <Card className="faq-card my-2">
                                <Accordion.Toggle as={Card.Header} className="faq-header" eventKey="0">
                                    What exactly is SEO?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        Search Engine Optimization refers to a comprehensive digital plan designed to help you rank better in search engines, namely Google. Our job it to put together a blueprint that encompasses a number of digital strategies to move you up the ranks and eventually increase your brand’s visibility to drive as much organic traffic as possible.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card className="faq-card my-2">
                                <Accordion.Toggle as={Card.Header} className="faq-header" eventKey="1">
                                    What’s the difference between Web Design and Web Development?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        Web design strictly refers to the visual layout of your website. While web development is the backend of your site and is the foundation for your site’s infrastructure. This is something that needs to be maintained, while unless you are changing the design, that can typically remain the same. 
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card className="faq-card my-2">
                                <Accordion.Toggle as={Card.Header} className="faq-header" eventKey="2">
                                    What’s a Sales Funnel?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="2">
                                    <Card.Body>
                                        Sales funnels are web pages creating for the sole purpose of generating a sale. The page is designed in a way that leads the user in the direction you want them to go, which is always the checkout button. 
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card className="faq-card my-2">
                                <Accordion.Toggle as={Card.Header} className="faq-header" eventKey="3">
                                    Can you get me on the first page of Google?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="3">
                                    <Card.Body>
                                        Any reputable SEO agency will never guarantee specific placements or results. Google is constantly evolving their rules and algorithms, and at Codev Media we evolve right along with them. SEO is a marathon, not a sprint. We help you build organic traffic for the long-term and only provide white-hat solutions. 
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default FAQBlock;