import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import HeroInner from "../components/heroinner"
import Features from "../components/features"
import FAQBlock from "../components/faqblock"

const Faq = () => (
  <Layout>
    <SEO title="FAQs" description="FAQs related to our web design services and sales funnel services" />
    <HeroInner title="Frequently Asked Questions" />
    <FAQBlock />
    <Features />
  </Layout>
)

export default Faq;