import React from 'react';
import {StaticImage} from 'gatsby-plugin-image';
import {Container, Row, Col, Card, CardDeck} from "react-bootstrap";
import "../styles/features.css";
import "animate.css";

const Features = () => {
    return (
            <Container className="features-section pt-5 pb-5" fluid>
                <Row className="justify-content-center mb-5">
                    <Col className="service-heading text-center col-8">
                        <div>
                            <span className="gradient-text blue">
                                Our Services
                            </span>
                        </div>
                        <h2 className="svc-h2 mt-3 mb-3">
                            What We Offer
                        </h2>
                        <p className="svc-text">
                            We provide numerous digital strategies to help with lead generation and conversions. Here are our most popular services that are the foundation for any successful digital marketing plan.
                        </p>
                    </Col>
                </Row>
                <Row className="ml-3 mr-3">
                <CardDeck className="">
                    <Card className="feat-card shadow py-3 px-2">
                        <StaticImage
                            src="../images/feat1.webp"
                            className="mx-auto"
                            placeholder="blurred"
                            Layout="constrained"
                            alt=""
                        />
                        <Card.Body>
                            <Card.Title>
                                Website Design
                            </Card.Title>
                            <Card.Text className="svc-text">
                                It’s one thing to have killer looking graphics. Don’t you also want a design that converts? Our website designs are engineered to do both. 
                            </Card.Text>                        
                        </Card.Body>
                    </Card>
                    <Card className="feat-card shadow py-3 px-2">
                        <StaticImage
                            src="../images/feat2.webp"
                            className="mx-auto"
                            placeholder="blurred"
                            Layout="constrained"
                            alt=""
                        />
                        <Card.Body>
                            <Card.Title>
                                SEO And Advertisement
                            </Card.Title>
                            <Card.Text className="svc-text">
                                Can your website be found through search? Our SEO experts will implement a plan to get you ranking, and our PPC ninjas will create campaigns that ensure maximum ROI.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="feat-card shadow py-3 px-2">
                        <StaticImage
                            src="../images/feat3.webp"
                            className="mx-auto"
                            placeholder="blurred"
                            Layout="constrained"
                            alt=""
                        />
                        <Card.Body>
                            <Card.Title>
                                Sales Funnel Design
                            </Card.Title>
                            <Card.Text className="svc-text">
                                Need your customers to ‘add to cart?’ Our sales funnel services offer a comprehensive blueprint designed to capture as many leads as possible. 
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </CardDeck>
                </Row>
                <Row className="my-5 ml-3 mr-3 align-items-center">
                    <Col>
                        <Row className="justify-content-center">
                            <Col>
                                <Card className="feat-card2 shadow px-3">
                                    <Row className="">
                                        <Col className="col-1 mt-3">
                                            <div className="step">
                                                1
                                            </div>
                                        </Col>
                                        <Col className="col-11">
                                        <Card.Body>
                                            <Card.Title>
                                                In-depth analysis of your systems
                                            </Card.Title>
                                            <Card.Text className="svc-text">
                                                Are you relying on out-of-date platforms? Our team of pros will do a deep dive into your systems and see what’s working and what needs improving. 
                                            </Card.Text>
                                        </Card.Body>
                                        </Col>
                                    </Row>
                                    
                                </Card>
                            </Col>
                        </Row>
                        <Row className="justify-content-center mt-5 mb-5">
                            <Col>
                                <Card className="feat-card2 shadow px-3">
                                    <Row className="">
                                        <Col className="col-1 mt-3">
                                            <div className="step">
                                                2
                                            </div>
                                        </Col>
                                        <Col className="col-11">
                                        <Card.Body>
                                            <Card.Title>
                                                Optimize your systems for better conversions
                                            </Card.Title>
                                            <Card.Text className="svc-text">
                                                Our web development Oklahoma headquarters is ready to get to work to provide web design services that focus on engagement, retention, and most importantly, sales. 
                                            </Card.Text>
                                        </Card.Body>
                                        </Col>
                                    </Row>
                                    
                                </Card>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col>
                                <Card className="feat-card2 shadow px-3">
                                    <Row className="">
                                        <Col className="col-1 mt-3">
                                            <div className="step">
                                                3
                                            </div>
                                        </Col>
                                        <Col className="col-11">
                                        <Card.Body>
                                            <Card.Title>
                                                Automate your systems to run seamlessly
                                            </Card.Title>
                                            <Card.Text className="svc-text">
                                                We’ll help you create an integrated UI/UX experience so all of your systems run flawlessly 24/7.
                                            </Card.Text>
                                        </Card.Body>
                                        </Col>
                                    </Row>
                                    
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} lg={6} className="mt-3">
                        <StaticImage
                            src="../images/digital-4.webp"
                            className="mx-auto"
                            placeholder="blurred"
                            layout="constrained"
                            alt=""
                        />
                    </Col>
                </Row>
                </Container>
    )
};

export default Features;